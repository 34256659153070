import { createSlice } from '@reduxjs/toolkit';
import User from '../model/User';
import userManager from '../utils/userManager';

export interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: userManager.loadUser()
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      userExpired: (state: UserState) => {
        return { ...state, user: null };
      },
      userSignedOut: (state: UserState) => {
        userManager.removeUser();
        return { ...state, user: null };
      }
    },
    extraReducers: {
    }
});

export default userSlice;
