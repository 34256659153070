import { createSlice, createAsyncThunk, createEntityAdapter, EntityState, createAction } from '@reduxjs/toolkit';
import api from '../utils/api';
import { AsyncStatus, AsyncStatusEnum } from '../model/common/AsyncStatus';
import { BrandingImageType, BrandingImage, BrandingImages } from '../model/BrandingImage';
import DocumentUploadResult from '../model/DocumentUploadResult';
import { ApplicationState } from '.';
import { ErrorResponse } from '../model/common/ErrorResponse';
import HCSResponse from '../model/common/Response';

const windowAlias: any = window;

export const fetchBrandingImages = createAsyncThunk('branding/fetchImages', () => {
    return api.get<BrandingImages>(`api/v1/documents/${BrandingImageType.DefaultImage}/raw`);
});

export const createBrandingImage = createAsyncThunk('branding/createImage', ({brandingImageType, brandingImage} : {
    brandingImageType: BrandingImageType,
    brandingImage: File
}) => {
    const formData = new FormData();
    formData.append("Document", brandingImage, brandingImage.name);
    formData.append("DocumentType", brandingImageType);

    return api.postHCSResponse<DocumentUploadResult>('api/v1/documents', {
      body: formData
    });
});

export const updateBrandingImage = createAsyncThunk('branding/updateImage', ({brandingImageType, brandingImage} : {
    brandingImageType: BrandingImageType,
    brandingImage: File
}) => {
    const formData = new FormData();
    formData.append("Document", brandingImage, brandingImage.name);
    formData.append("DocumentType", brandingImageType);

    return api.putHCSResponse<DocumentUploadResult>('api/v1/documents', {
      body: formData
    });
});

const brandingImagesAdapter = createEntityAdapter<BrandingImage>();

declare type BrandingState = EntityState<BrandingImage> & {
    fetchStatus: AsyncStatus,
    fetchError?: string,
    uploadStatus: AsyncStatus,
    uploadError?: string | string[]
};

const initialState: BrandingState = brandingImagesAdapter.getInitialState({
    fetchStatus: AsyncStatusEnum.IDLE,
    uploadStatus: AsyncStatusEnum.IDLE
});

const brandingSlice = createSlice({
    name: 'branding',
    initialState,
    reducers: {
       dragAndDropErrored: (state, action) => {
           state.uploadStatus = AsyncStatusEnum.IDLE;
           state.uploadError = action.payload.errors;
       }
    },
    extraReducers: {
        [fetchBrandingImages.pending.toString()]: (state, action) => {
            state.fetchStatus = AsyncStatusEnum.LOADING;
            state.fetchError = undefined;
        },
        [fetchBrandingImages.fulfilled.toString()]: (state, action) => {
            state.fetchStatus = AsyncStatusEnum.SUCCEEDED;
            state.fetchError = undefined;
            let brandingImages: BrandingImage[] = [
                {
                    id: BrandingImageType.DefaultImage,
                    uri: action.payload.embeddedResourceString,
                    type: BrandingImageType.DefaultImage
                } //TODO change api once we have more than one branding image usage
            ];
            brandingImagesAdapter.setAll(state, brandingImages);
        },
        [fetchBrandingImages.rejected.toString()]: (state, action) => {
            state.fetchStatus = AsyncStatusEnum.FAILED;
            state.fetchError = action.error.message;            
        },        
        [createBrandingImage.pending.toString()]: (state, action) => {
            state.uploadStatus = AsyncStatusEnum.LOADING;
            state.uploadError = undefined;
        },
        [createBrandingImage.fulfilled.toString()]: (state, action) => {
            state.uploadStatus = AsyncStatusEnum.SUCCEEDED;
            state.uploadError = undefined;
            //TODO have api return cloudfront uri incase it changes.
        },
        [createBrandingImage.rejected.toString()]: (state, action) => {
            state.uploadStatus = AsyncStatusEnum.FAILED;
            try {
                let parsedObject = JSON.parse(action.error.message);
                let validationResponse = parsedObject as ErrorResponse;
                let errorResponse = parsedObject as HCSResponse<DocumentUploadResult>;

                if (errorResponse?.content?.errorCode === 1) {
                    //Normal Exception
                    state.uploadError = "There was an error while uploading the selected file. Please try again.";
                }
                else if (errorResponse?.content?.errorCode === 2) {
                    //Virus scan failed                
                    state.uploadError = "The virus scanner detected an issue with the selected file. Please try a different file.";
                }
                else if (errorResponse?.content?.errorCode === 3) {
                    //File too large
                    state.uploadError = windowAlias.config.logoFileOptions.validationMessages.invalidFileSize;
                }
                else {
                    //Validation Error
                    let createError: string[] = [];
                    if (validationResponse !== undefined)
                    {
                        createError = validationResponse.summary;
                    }

                    state.uploadError = createError.length === 0 ? undefined : createError;
                }
            }
            catch {
                //Normal Exception
                state.uploadError = "There was an error while uploading the selected file. Please try again.";
            }
        },        
        [updateBrandingImage.pending.toString()]: (state, action) => {
            state.uploadStatus = AsyncStatusEnum.LOADING;
            state.uploadError = undefined;
        },
        [updateBrandingImage.fulfilled.toString()]: (state, action) => {
            state.uploadStatus = AsyncStatusEnum.SUCCEEDED;
            state.uploadError = undefined;
        },
        [updateBrandingImage.rejected.toString()]: (state, action) => {
            state.uploadStatus = AsyncStatusEnum.FAILED;
            try {
                let parsedObject = JSON.parse(action.error.message);
                let validationResponse = parsedObject as ErrorResponse;
                let errorResponse = parsedObject as HCSResponse<DocumentUploadResult>;

                if (errorResponse?.content?.errorCode === 1) {
                    //Normal Exception
                    state.uploadError = "There was an error while uploading the selected file. Please try again.";
                }
                else if (errorResponse?.content?.errorCode === 2) {
                    //Virus scan failed                
                    state.uploadError = "The virus scanner detected an issue with the selected file. Please try a different file.";
                }
                else if (errorResponse?.content?.errorCode === 3) {
                    //File too large
                    state.uploadError = windowAlias.config.logoFileOptions.validationMessages.invalidFileSize;
                }
                else {
                    //Validation Error
                    let createError: string[] = [];
                    if (validationResponse !== undefined)
                    {
                        createError = validationResponse.summary;
                    }

                    state.uploadError = createError.length === 0 ? undefined : createError;
                }
            }
            catch {
                //Normal Exception
                state.uploadError = "There was an error while uploading the selected file. Please try again.";
            }
        }
    }
});

export const { dragAndDropErrored } = brandingSlice.actions;

export default brandingSlice;

const selectors = brandingImagesAdapter.getSelectors<ApplicationState>(state => state.branding);

export const {
  selectAll
} = selectors;
