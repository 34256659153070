export enum BrandingImageType {
    DefaultImage = "DefaultImage"
}

export interface BrandingImages {
    embeddedResourceString?: string
}

export interface BrandingImage {
    id: BrandingImageType, //duplicate value for redux pattern
    uri?: string,
    type: BrandingImageType
} 

export default BrandingImage;

