import React, {useCallback, useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useDropzone, FileRejection } from 'react-dropzone'
import classNames from 'classnames';

import AsyncStatus, { AsyncStatusEnum } from '../../model/common/AsyncStatus';
import { AppDispatch, ApplicationState } from '../../store';
import Loading from '../Loading';
import { fetchBrandingImages, createBrandingImage, updateBrandingImage, selectAll, dragAndDropErrored } from '../../store/Branding';
import { BrandingImageType } from '../../model/BrandingImage';
import { ErrorSummary } from '../common';
import toastHelper from '../../utils/toastHelper';

const windowAlias: any = window;

const allowedFileTypes: string[] = windowAlias.config.logoFileOptions.allowedFileTypes;

const dragAndDropErrorMap: any = {
  'file-invalid-type': windowAlias.config.logoFileOptions.validationMessages.invalidFileType,
  'file-too-large': windowAlias.config.logoFileOptions.validationMessages.invalidFileSize
};

const BrandingLogos = function () {  
  const dispatch : AppDispatch = useDispatch();  
  const fetchStatus = useSelector<ApplicationState, AsyncStatus>(state => state.branding.fetchStatus);
  const uploadStatus = useSelector<ApplicationState, AsyncStatus>(state => state.branding.uploadStatus);
  const uploadError = useSelector<ApplicationState, string | string[] | undefined>(state => state.branding.uploadError);
  const brandingImages = useSelector(selectAll);
  const [defaultBrandingImageError, setDefaultBrandingImageError] = useState(false);
  
  // Fetch the documents if they haven't been fetched yet.
  useEffect(() => {
    if (fetchStatus === 'idle') {
      dispatch(fetchBrandingImages());
    }
  }, [fetchStatus, dispatch]);

  let defaultLogo = brandingImages.filter((brandingImage) => { return brandingImage.id === BrandingImageType.DefaultImage }).pop();

  const onDropAccepted = useCallback(async acceptedFiles => {
    // call api here/action
    let uploadAction = defaultLogo?.uri === undefined ? createBrandingImage : updateBrandingImage;
    const resultAction = await dispatch(uploadAction({
      brandingImageType: BrandingImageType.DefaultImage,
      brandingImage: acceptedFiles.pop()
    }));

    if (uploadAction.fulfilled.match(resultAction)) {
      setDefaultBrandingImageError(false);
      await dispatch(fetchBrandingImages());
      toastHelper.success("Logo uploaded successfully!");
    }
    else if (uploadAction.rejected.match(resultAction)) {
      toastHelper.error("Logo failed to upload.");
    }
  }, [defaultLogo, dispatch]);

  const onDropRejected = useCallback(async (rejectedFiles: FileRejection[]) => {
    var errors: string[] = [];
    rejectedFiles.forEach((rejction) => {
      rejction.errors.forEach((error) => {
        errors.push(dragAndDropErrorMap[error.code]);
      });
    });

    await dispatch(dragAndDropErrored({ errors: errors }));
    toastHelper.error("Logo failed to upload.");
  }, [dispatch]);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    maxFiles: 1,
    minSize: 0,
    maxSize: windowAlias.config.logoFileOptions.maximumFileBytes,
    multiple: false,
    accept: allowedFileTypes,
    onDropAccepted: onDropAccepted,
    onDropRejected: onDropRejected,
    disabled: uploadStatus === AsyncStatusEnum.LOADING ? true : false
  });
  
  let submitImage = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //do nothing handled by react-dropzone
  };

  let dragAndDropClassNames = classNames({
    'hcs-file-drag-drop': true,
    'hcs-file-drag-drop-drop-active': isDragActive
  });

  let successfulLogo = defaultLogo?.uri !== undefined && fetchStatus === AsyncStatusEnum.SUCCEEDED && !defaultBrandingImageError;
  let failedLogo = (defaultLogo?.uri !== undefined && fetchStatus === AsyncStatusEnum.SUCCEEDED && defaultBrandingImageError) || fetchStatus === AsyncStatusEnum.FAILED;
  let noLogo = defaultLogo?.uri === undefined && fetchStatus === AsyncStatusEnum.SUCCEEDED;

  return (
    <Row>
      <Col xs={12}>
        <h1>Logos</h1>
      </Col>      
      <Col xs={12}>
        <Row>
          <Col xs={12}>
            <h2>Current Logo</h2>
          </Col>
          <Col xs={12}>
            <div className="hcs-logo-preview d-flex justify-content-start align-items-center">
              <Loading isLoading={fetchStatus === AsyncStatusEnum.LOADING} size="sm">
                { successfulLogo && <img src={`${defaultLogo?.uri}`} className="hcs-logo-preview-item" alt="Could not load logo." onError={() => {setDefaultBrandingImageError(true);}} /> }                
                { failedLogo && 
                  <div className="hcs-message hcs-message--danger hcs-logo-message">
                    <p className="hcs-message__body"><i className="fas fa-unlink mr-3" /><span>There was an issue displaying your logo. Refresh the page and try again. If you continue to experience issues, confirm the file you are attempting to import is a valid image file.</span></p>
                  </div>
                }
                { noLogo &&                 
                  <div className="hcs-message hcs-logo-message">
                    <p className="hcs-message__body">No logo has been uploaded.</p>
                  </div>
                }
              </Loading>
            </div>
          </Col>
          { uploadStatus === AsyncStatusEnum.SUCCEEDED && !failedLogo &&
          <Col xs={12}>
            <div className="hcs-logo-preview d-flex justify-content-start align-items-center">
              <div className="hcs-message hcs-message--caution hcs-logo-message">
                <p className="hcs-message__body"><i className="fas fa-exclamation-circle mr-3" /><span><strong>Note:</strong> It may take several minutes for updated logos to display across all locations.</span></p>
              </div>
            </div>
          </Col> }
        </Row>
      </Col>
      <Col xs={12}>
        <span>&nbsp;</span>
      </Col>      
      <Col xs={12}>
        <span><h2>Upload New Logo</h2></span>
      </Col>
      { uploadError !== undefined && 
      <Col xs={12}>
        <ErrorSummary errors={uploadError} />
      </Col>
      } 
      <Col xs={12}>
        <form onSubmit={submitImage}>
          <div {...getRootProps()} className={dragAndDropClassNames}>
            <input {...getInputProps()} />
              <Row className="m-auto">
                <Col xs={12} className="text-center">
                  <Loading isLoading={uploadStatus === AsyncStatusEnum.LOADING}>
                    <Row>                    
                      <Col xs={12} className="text-center">
                        <i className="fas fa-cloud-upload-alt fa-3x" />
                      </Col>
                      <Col xs={12}>&nbsp;</Col>
                      <Col xs={12} className="text-center">                      
                        {
                          isDragActive ?
                          <div>Drop logo file here.</div> :
                          <div>Drag and drop logo file here, or click to browse for your file.</div>
                        }
                      </Col>
                    </Row>
                  </Loading>
                </Col>
              </Row>
          </div>
        </form>
      </Col>
      <Col xs={12} className="mt-3 text-start">
        <strong>Recommended image specifications</strong>
        <ul className="pl-4">
          <li>File Type: .svg or .png</li>
          <li>Minimum width: 375px</li>
          <li>Minimum height: 75px</li>
          <li>Aspect Ratio: 5:1</li>
          <li>Transparent Background</li>
        </ul>
      </Col>
      <Col xs={12} className="mt-3 text-start">
        <strong>Required image specifications</strong>
        <ul className="pl-4">
          <li>Allowed File Type: {allowedFileTypes.join(', ')}</li>
          <li>Maximum File Size: 5MB</li>
        </ul>
      </Col>
      <Col xs={12} className="mt-2 text-start">
        <strong>Logo is displayed in the following areas:</strong>        
        <ul className="pl-4">
          <li>Job Seeker Login</li>
        </ul>
      </Col>
    </Row>
  );
}

export default BrandingLogos;