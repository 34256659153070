import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from '../store';
import AuthenticatedLayout from './AuthenticatedLayout';
import User from '../model/User';

type AuthRouteProps =
  RouteProps & {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  };

const AuthRoute = function ({ component, ...rest }: AuthRouteProps) {
  const user = useSelector<ApplicationState, User | null>(state => state.user.user);
  const history = useHistory();
  useEffect(() => {
    if (!user) {
      history.push("/LoggedOut");
    }
  }, [user, history]);

  return (<Route
    {...rest}
    children={({ match }) => {
      if (match && user) {
        var renderedComponent = React.createElement(component, { ...rest });
        return (
          <AuthenticatedLayout>
            {renderedComponent}
          </AuthenticatedLayout>
        );
      } else return null;
    }} />);
}

export default AuthRoute;