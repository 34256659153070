import * as React from 'react';
import { Spinner, Row, Col } from 'reactstrap';

interface ILoadingProps {
  isLoading: boolean,
  size?: string,
  children?: React.ReactNode
}

export default class Loading extends React.PureComponent<ILoadingProps> {

  public render() {
    if (this.props.isLoading) {
      return <React.Fragment>
        <Row>
          <Col xs={12} className="text-center">
            <Spinner animation="border" variant="primary" size={this.props.size} />
          </Col>
        </Row>
      </React.Fragment>;
    } else if (!!this.props.children) {
      return this.props.children;
    } else return null;
  }
};
