import * as React from 'react';

var LoggedOut = () => (
  <React.Fragment>
    <div className="text-center">
      <h1>You have logged out.</h1>
    </div>
  </React.Fragment>
);

export default LoggedOut;
