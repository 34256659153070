import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';
import React from 'react';
import AnonymousLayout from './AnonymousLayout';

type AnonymousRouteProps =
  RouteProps & {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  };

const AnonymousRoute = function ({ component, ...rest }: AnonymousRouteProps) {
  
  return (<Route
    {...rest}
    children={({ match }) => {
      if (match) {
        var renderedComponent = React.createElement(component, { ...rest });
        return (
          <AnonymousLayout>
            {renderedComponent}
          </AnonymousLayout>
        );
      } else return null;
    }} />);
}

export default AnonymousRoute;