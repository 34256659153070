import * as React from 'react';
import AuthRoute from './components/AuthRoute';
import AnonymousRoute from './components/AnonymousRoute';
import Pages from './components/pages';

import './css/custom.css'
import './css/hcs_design_system.css'
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router';

var App = () => (
  <>
    <AuthRoute exact path="/" component={Pages.Home} />
    <AuthRoute path="/branding/logos" component={Pages.BrandingLogos} />
    <AnonymousRoute path="/loggedOut" component={Pages.LoggedOut} />
  </>
);

export default App;