import './scss/bootstrap_custom.scss';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer, Slide } from 'react-toastify';
import App from './App';
import store, { history } from './store';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ToastContainer transition={Slide} pauseOnFocusLoss/>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'));
