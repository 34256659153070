import Event from "./event";

const TimerDuration = 5; //seconds

export default class Timer extends Event {
    private _timerHandle: NodeJS.Timeout | null;
    private _expiration ?: number;

    constructor() {
        super();
        this._timerHandle = null;
    }

    get now() : number {
        return Date.now() / 1000;
    }

    init(duration: any) {
        if (duration <= 0) {
            duration = 1;
        }
        duration = parseInt(duration);

        var expiration = this.now + duration;
        if (this.expiration === expiration && this._timerHandle) {
            // no need to reinitialize to same expiration, so bail out
            return;
        }

        this.cancel();

        this._expiration = expiration;

        // we're using a fairly short timer and then checking the expiration in the
        // callback to handle scenarios where the browser device sleeps, and then
        // the timers end up getting delayed.
        var timerDuration = TimerDuration;
        if (duration < timerDuration) {
            timerDuration = duration;
        }
        this._timerHandle = setInterval(this._callback.bind(this), timerDuration * 1000);
    }
    
    get expiration() {
        return this._expiration;
    }

    cancel() {
        if (this._timerHandle) {
            clearInterval(this._timerHandle);
            this._timerHandle = null;
        }
    }

    private _callback() {
        if (this._expiration !== undefined && this._expiration <= this.now) {
            this.cancel();
            super.raise();
        }
    }
}