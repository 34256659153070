import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import userManager from '../utils/userManager';
import api from '../utils/api';
import UserSlice from './User';
import BrandingSlice from './Branding';

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
const reducers = {
  branding: BrandingSlice.reducer,
  user: UserSlice.reducer
};

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
export const history = createBrowserHistory({ basename: baseUrl });

const rootReducer = combineReducers({
  ...reducers,
  router: connectRouter(history)
});

export type ApplicationState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware(history))
});

let currentAccessToken: string | undefined;
function selectAccessToken(state: ApplicationState) {
  return state.user.user?.accessToken;
}
function handleAccessTokenChange() {
  let previousAccessToken = currentAccessToken;
  currentAccessToken = selectAccessToken(store.getState());
  if (previousAccessToken !== currentAccessToken) {
    api.setAccessToken(currentAccessToken ?? "");
  }
}
store.subscribe(handleAccessTokenChange);

userManager.events.addUserSignedOut(() => {
  store.dispatch(UserSlice.actions.userSignedOut());
});
userManager.events.addAccessTokenExpired(() => {
  store.dispatch(UserSlice.actions.userExpired());
})

export default store;

export type AppDispatch = typeof store.dispatch;
