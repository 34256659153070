import * as React from 'react';
import { connect } from 'react-redux';
import { Navbar, NavbarBrand, NavItem, Nav } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { ApplicationState } from '../store';
import './NavMenu.css';
import SymplrLogo from '../images/small-symplr-logo.svg';
// import HCSLogoSmall from '../images/hcs-logo-horiz-no-text.svg';
import { UserState } from '../store/User';
import { AppDispatch } from '../store';

type NavMenuProps =
  UserState
  & { 
    dispatch: AppDispatch
  };

//Alias the window propert so we do not have issues with using window.config that typescript doesn't recognize.
const windowAlias: any = window;

class NavMenu extends React.PureComponent<NavMenuProps, { isOpen: boolean }> {
  public state = {
    isOpen: false
  };

  public render() {
    return (
      <header>
        <Navbar className="d-flex flex-column justify-content-start navbar-hcs navbar-hcs-vertical border-right box-shadow mr-3" light>
          <NavbarBrand>
            <img src={SymplrLogo} alt="symplr" className="hcs-branding-logo" />
            {/* <img src={HCSLogoSmall} alt="HealthcareSource" className="d-lg-none" /> */}
          </NavbarBrand>
          <div className="w-100 text-start">
            <div className="text-white-50 fs-6 fw-bold text-uppercase px-3 small mb-2 mt-2">Branding</div>
            <Nav className="navbar-nav flex-grow m-0 mt-1" vertical>
              <NavItem>
                <NavLink to="/branding/logos" className="font-italic m-0 nav-link" activeClassName="active">
                  <div className="px-3">
                    <i className="far fa-images mr-3 fa-fw" />Logos
                  </div>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </Navbar>
      </header>
    );
  }

  private toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
}

export default connect(
  (state: ApplicationState) => state.user
)(NavMenu);
