import React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';

type AuthenticatedLayoutProps = {
  children?: React.ReactNode
};

var AuthenticatedLayout = (props: AuthenticatedLayoutProps) => (
  <React.Fragment>
      <NavMenu />
      <Container fluid className="text-start hcs-content">
          {props.children}
      </Container>
  </React.Fragment>
);

export default AuthenticatedLayout;