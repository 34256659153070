import { toast, ToastContent, ToastOptions } from 'react-toastify';

type ToastSymbol = "fa-check-square" | "fa-times-circle";

class ToastHelper {
  success(message: string, symbol: ToastSymbol = 'fa-check-square') {
    this.showToast(toast.success, symbol, message);
  }
  error(message: string, symbol: ToastSymbol = 'fa-times-circle') {
    this.showToast(toast.error, symbol, message);
  }

  private showToast(func: (content: ToastContent, options?: ToastOptions) => React.ReactText, symbol: ToastSymbol, message: string) {
    var className = `fas ${symbol}`;
    var content = <span><i className={className} />&nbsp;{message}</span>;
    func(content, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined
    })
  }
}

const toastHelper = new ToastHelper();

export default toastHelper;