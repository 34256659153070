interface IErrorSummaryProps {
    errors: string | string[] | undefined;
}

const ErrorSummary = function (props: IErrorSummaryProps) {
    if (props.errors === undefined)
        return null;
    
    return <div className="hcs-message hcs-message--danger hcs-file-drag-drop-errors">
        <p className="hcs-message__body">
            {Array.isArray(props.errors) && props.errors.length > 1 && 
                <ul key="errors">
                    {props.errors.map((str, index) => {
                        return <li key={index}>{str}</li>
                    })}
                </ul>
            }
            {Array.isArray(props.errors) && props.errors.length === 1 && <span>{props.errors[0]}</span>}
            {typeof props.errors === "string" && <span>{props.errors}</span>}
        </p>
    </div>;
}

export default ErrorSummary;