import HCSResponse from '../model/common/Response';

class Api {
  accessToken: string;

  constructor() {
    this.accessToken = "";
  }

  setAccessToken(newAccessToken: string) {
    this.accessToken = newAccessToken;
  }

  get<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    return this.parseResponse(this.sendRequest("GET", input, init));
  }

  getHCSResponse<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    return this.parseHCSResponse(this.sendRequest("GET", input, init));
  }

  post<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    return this.parseResponse(this.sendRequest("POST", input, init));
  }

  postHCSResponse<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    return this.parseHCSResponse(this.sendRequest("POST", input, init));
  }

  put<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    return this.parseResponse(this.sendRequest("PUT", input, init));
  }

  putHCSResponse<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    return this.parseHCSResponse(this.sendRequest("PUT", input, init));
  }

  delete(input: RequestInfo, init?: RequestInit): Promise<Response> {
    return this.checkResponse(this.sendRequest("DELETE", input, init));
  }

  deleteHCSResponse<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
    return this.parseHCSResponse(this.sendRequest("DELETE", input, init));
  }

  sendRequest(method: "GET" | "POST" | "PUT" | "DELETE", input: RequestInfo, init?: RequestInit): Promise<Response> {
    init = init || {};
    init.method = method;
    init.headers = {
      ...init.headers
    } as Record<string,string>;

    if (this.accessToken !== "") {
      init.headers.Authorization = `bearer ${this.accessToken}`;
    }

    return fetch(input, init);
  }

  private async checkResponse(responsePromise: Promise<Response>) : Promise<Response> {
    const response = await responsePromise;

    if (response.ok || response.status === 400) {
      return response;
    }

    if (response.status === 404) {
      return new Response("{}", { status: 404 });
    }

    const responseBody = await response.text();
    throw new Error(responseBody ?? response.statusText);
  }

  private async parseResponse<T>(responsePromise: Promise<Response>): Promise<T> {
    const response = await this.checkResponse(responsePromise);

    if (response.status === 400) {
      throw new Error(JSON.stringify(await response.json()));
    }

    return response.json() as Promise<T>;
  }

  private async parseHCSResponse<T>(responsePromise: Promise<Response>): Promise<T> {
    const response = await responsePromise;
    
    if (response.status === 404) {
      return {} as T;
    }

    if (response.status === 400) {
      throw new Error(JSON.stringify(await response.json()));
    }

    if (!response.ok) {
      // Try to extract an HCSResponse from the response body.
      let hcsResponse: HCSResponse<T> | any | undefined;
      try {
        hcsResponse = await (response.json() as Promise<HCSResponse<T> | any>);
      } catch(e){}
      if (hcsResponse?.message !== undefined && hcsResponse?.content === undefined) {
        throw new Error(hcsResponse.message);
      }
      throw new Error(JSON.stringify(hcsResponse) ?? response.statusText);
    }
    const hcsResponse = await (response.json() as Promise<HCSResponse<T>>)
    if (!hcsResponse.isSuccessful) {
      throw new Error(hcsResponse.message);
    }
    return hcsResponse.content;
  }
}

const api = new Api();

export default api;