import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import SymplrLogo from '../images/small-symplr-logo.svg';

type AnonymousLayoutProps = {
  children?: React.ReactNode
};

var AnonymousLayout = (props: AnonymousLayoutProps) => (
  <React.Fragment>
    <Container>
      <Row className="hcs-anonymous">
        <Col xs={12} className="d-flex justify-content-center">
          <img src={SymplrLogo} alt="symplr" className="hcs-branding-logo" />
        </Col>
        <Col xs={12}>
          {props.children}
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);

export default AnonymousLayout;