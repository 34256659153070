import User from '../model/User';
import { isExpired, decodeToken } from "react-jwt";
import Event from './event';
import Timer from './timer';

const windowAlias: any = window;
const DefaultAccessTokenExpiringNotificationTime = 60; //seconds

function getUserExpiration(user: User) : Date {
    let claims = decodeToken(user.accessToken);
    return new Date(parseInt(claims.exp + "000"));
}

export class UserStore {
    private get _cookieName() {
        return "client_admin";
    }

    set(user: User) {
        let value = encodeURIComponent(JSON.stringify(user));
        let expires = getUserExpiration(user);
        document.cookie = `${this._cookieName}=${value}; path=/; secure; samesite=none; expires=${expires.toUTCString()}`;
    }

    get() : User | null {
        let cookieNvps = document.cookie.split(';');
        let value: string | null = null;
        cookieNvps.forEach(nvp => {
            let pair = nvp.split('=');
            let key = pair[0].trim();
            if (key === this._cookieName) {
                value = typeof pair[1] !== 'undefined' && pair[1] !== null && pair[1].length > 0 ? decodeURIComponent(pair[1]) : null;
            }
        });
        if (value) {
            return JSON.parse(value) as User;
        }
        return null;
    }

    remove() {
        document.cookie = this._cookieName + '=; Path=/; secure; samesite=none; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}

export class UserManagerEvents {
    private _userLoaded : Event;
    private _userSignedOut : Event;
    private _accessTokenExpiring : Timer;
    private _accessTokenExpired : Timer;

    constructor() {
        this._userLoaded = new Event();
        this._userSignedOut = new Event();
        this._accessTokenExpiring = new Timer();
        this._accessTokenExpired = new Timer();
    }

    unload() {
        this._accessTokenExpired.cancel();
        this._accessTokenExpiring.cancel();
    }

    load(user: User) {
        let duration = (getUserExpiration(user).getTime() - (new Date()).getTime()) / 1000;
        if (duration > 0) {
            let expiring = duration - DefaultAccessTokenExpiringNotificationTime;
            if (expiring <= 0) {
                expiring = 1;
            }
            this._accessTokenExpiring.init(expiring);
        } 
        else {
            this._accessTokenExpiring.cancel();
        }
        let expired = duration + 1;
        this._accessTokenExpired.init(expired);
    }

    addAccessTokenExpiring(cb: any) {
        this._accessTokenExpiring.addHandler(cb);
    }
    removeAccessTokenExpiring(cb: any) {
        this._accessTokenExpiring.removeHandler(cb);
    }

    addAccessTokenExpired(cb: any) {
        this._accessTokenExpired.addHandler(cb);
    }
    removeAccessTokenExpired(cb: any) {
        this._accessTokenExpired.removeHandler(cb);
    }

    addUserLoaded(cb: any) {
        this._userLoaded.addHandler(cb);
    }
    removeUserLoaded(cb: any) {
        this._userLoaded.removeHandler(cb);
    }

    addUserSignedOut(cb: any) {
        this._userSignedOut.addHandler(cb);
    }
    removeUserSignedOut(cb: any) {
        this._userSignedOut.removeHandler(cb);
    }
    raiseUserSignedOut() {
        this._userSignedOut.raise();
    }
}

export interface UserManagerSettings {
    authority: string
};

interface TokenResponse {
    content: User
}

export class UserManager {
    private _userStore : UserStore;
    private _settings : UserManagerSettings;
    private _events: UserManagerEvents;
    private _storeHandle: NodeJS.Timeout | null;

    constructor(settings: UserManagerSettings = { authority: windowAlias.config.authentication.authority}, userStore = new UserStore()) {
        this._userStore = userStore;
        this._settings = settings;
        this._events = new UserManagerEvents();
        this._events.addAccessTokenExpired(this.removeUser.bind(this));
        this._storeHandle = null;
    }

    get events() {
        return this._events;
    }

    loadUser(): User | null {
        let user = this._userStore.get();
        if (user !== null) {
            if (!isExpired(user.accessToken)) {
                this._loadUser(user);
            }
            else {
                this.removeUser();
            }
        }
        return user;
    }

    removeUser() {
        this._clearStoreHandle();
        this._userStore.remove();
        this._events.unload();
    }

    private _clearStoreHandle() {
        if (this._storeHandle !== null) {
            clearInterval(this._storeHandle);
            this._storeHandle = null;
        }
    }

    private _storeCheck() {
        if (this._userStore.get() === null) {
            // user probably signed out in a separate tab
            this._events.raiseUserSignedOut();
        }
    }

    private _loadStoreHandle() {
        this._storeHandle = setInterval(this._storeCheck.bind(this), 5 * 1000);
    }

    private _loadUser(user: User | null) {
        if (user !== null) {
            this._events.load(user);
            this._clearStoreHandle();
            this._loadStoreHandle();
        }
    }
}

const userManager = new UserManager();
export default userManager;